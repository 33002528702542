import { useContext } from "react";
import { GlobalStatesContext } from "Providers/context";
import Daily from "components/Dashboard/Casinos/Daily";
import MonthlyComparisons from "components/Dashboard/Casinos/MonthlyComparisons";
import MonthyComparasonsForYears from "components/Dashboard/Casinos/MonthyComparasonsForYears";
import AnnualAccumulated from "components/Dashboard/Casinos/AnnualAccumulated";
import GraphMonthy from "components/Dashboard/Casinos/GraphMonthy";
import GraphAnnual from "components/Dashboard/Casinos/GraphAnnual";
import Graph from "components/Dashboard/Casinos/GraphProgression";

export default function Casinos() {
  const { state } = useContext(GlobalStatesContext);

  return (
    <>
      <div className="containerLeft reduced">
        <div className="tituloTablero">
          <div className="iconLarge"></div>Casinos
        </div>
        <div className="card cardMedium">
          <Daily date={state.date} />
        </div>
        <div className="card cardMedium">
            <Graph date={state.date} />
        </div>
      </div>
      <div className="containerRight expanded">
        <MonthlyComparisons date={state.date} />
        
        <MonthyComparasonsForYears date={state.date} />
        <AnnualAccumulated date={state.date} />
        <div className="containerCardGraphRight">
          <GraphMonthy date={state.date} />
          <GraphAnnual date={state.date} />
        </div>
      </div>
    </>
  );
}
