import CountUp from "react-countup";
interface NumbersProps {
  value: number;
  intenger?: boolean;
}

const Negative = (numb: number): string => {
  if (numb < 0) {
    return "Numbers negativeNumbers";
  } else {
    return "Numbers";
  }
};

const isIntenger = (val: boolean | undefined): number => {
  return val ? 0 : 2;
};

const Max = (val: number): number => {
  return val > 1000000000 ? val / 1000000 : val;
};

const isBig = (val: number): string => {
  return val > 1000000000 ? "M" : "";
};

const color = (value: number): string => {
  let classTL = "";
  if (value > 20) {
    classTL = "green";
  }
  if (value > 10 && value <= 20) {
    classTL = "blue";
  }
  if (value > 5 && value <= 10) {
    classTL = "yellow";
  }
  if (value < 5) {
    classTL = "red";
  }
  return `statCircle ${classTL}`;
};

const getTitle = (value: number): string => {
  let classTL = "";
  if (value > 20) {
    classTL = "Valores mayores a 20%";
  }
  if (value > 10 && value <= 20) {
    classTL = "Valores mayores a 10% y menores a 20%";
  }
  if (value > 5 && value <= 10) {
    classTL = "Valores mayores a 5% y menores a 10%";
  }
  if (value < 5) {
    classTL = "Valores mayores a 5%";
  }
  return `${classTL}`;
};

export default function TrafficLight(props: NumbersProps) {
  return (
    <>
      <CountUp
        className={Negative(props.value)}
        end={Max(props.value)}
        decimals={isIntenger(props.intenger)}
        decimal=","
        separator="."
      />
      {isBig(props.value)}
      <span>%</span>
      <div className={color(props.value)} title={getTitle(props.value)}></div>
    </>
  );
}
