/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LineOptions,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { getListadoUtilidadesPorDiaMensual } from "Functions/tableroApi";
import { dateCompProps, Graph } from "Models/models";
import { ParseYearMonthDay } from "Functions/functions";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options: Partial<LineOptions> = {
  tension: 1,
};

const labels = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
];

const businessUnit: string[] = [];
const businessUnitDates: string[] = [];

export default function MonthlyProgressionGraph(props: dateCompProps) {
  const [data, setdata] = useState<Graph.ChartData>();
  const [source, setsource] = useState(false);

  useEffect(() => {
    const DateSplit = ParseYearMonthDay(props.date);
    if (DateSplit !== undefined) {
     const fecha  =  Number(props.date.replace("-", "").replace("-", ""));
      getListadoUtilidadesPorDiaMensual(fecha)
        .then((accDetailed) => {
          console.log(accDetailed)
          const accDetailedAux = accDetailed;
          const UtilidadesNetaChajari: any[] = [];
          const UtilidadesNetaColon: any[] = [];
          const UtilidadesNetaConcordia: any[] = [];
          const UtilidadesNetaFederacion: any[] = [];
          const UtilidadesNetaGualeguaychu: any[] = [];
          const UtilidadesNetaLaPaz: any[] = [];
          const UtilidadesNetaParana: any[] = [];
          const UtilidadesNetaVictoria: any[] = [];

          accDetailed.forEach((acc) => {
            if (!businessUnit.includes(acc.Nombre)) {
              businessUnit.push(acc.Nombre);
            }
          });

          accDetailed.forEach((acc) => {
            if (!businessUnitDates.includes(acc.Fecha)) {
              businessUnitDates.push(acc.Fecha);
            }
          });

          accDetailedAux.forEach((utl) => {
            /**
             * Recaudacion o Utilidad
             */
            if (source) {
              if (utl.Nombre === "Chajarí") {
                UtilidadesNetaChajari.push(utl.Recaudacion);
                // console.log(utl.Nombre);
              }
              if (utl.Nombre === "Colón") {
                UtilidadesNetaColon.push(utl.Recaudacion);
                // console.log(utl.Nombre);
              }
              if (utl.Nombre === "Concordia") {
                UtilidadesNetaConcordia.push(utl.Recaudacion);
                // console.log(utl.Nombre);
              }
              if (utl.Nombre === "Federación") {
                UtilidadesNetaFederacion.push(utl.Recaudacion);
                // console.log(utl.Nombre);
              }
              if (utl.Nombre === "Gualeguaychú") {
                UtilidadesNetaGualeguaychu.push(utl.Recaudacion);
                // console.log(utl.Nombre);
              }
              if (utl.Nombre === "La Paz") {
                UtilidadesNetaLaPaz.push(utl.Recaudacion);
                // console.log(utl.Nombre);
              }
              if (utl.Nombre === "Paraná") {
                UtilidadesNetaParana.push(utl.Recaudacion);
                // console.log(utl.Nombre);
              }
              if (utl.Nombre === "Victoria") {
                UtilidadesNetaVictoria.push(utl.Recaudacion);
                // console.log(utl.Nombre);
              }
            } else {
              if (utl.Nombre === "Chajarí") {
                UtilidadesNetaChajari.push(utl.Utilidad);
                // console.log(utl.Nombre);
              }
              if (utl.Nombre === "Colón") {
                UtilidadesNetaColon.push(utl.Utilidad);
                // console.log(utl.Nombre);
              }
              if (utl.Nombre === "Concordia") {
                UtilidadesNetaConcordia.push(utl.Utilidad);
                // console.log(utl.Nombre);
              }
              if (utl.Nombre === "Federación") {
                UtilidadesNetaFederacion.push(utl.Utilidad);
                // console.log(utl.Nombre);
              }
              if (utl.Nombre === "Gualeguaychú") {
                UtilidadesNetaGualeguaychu.push(utl.Utilidad);
                // console.log(utl.Nombre);
              }
              if (utl.Nombre === "La Paz") {
                UtilidadesNetaLaPaz.push(utl.Utilidad);
                // console.log(utl.Nombre);
              }
              if (utl.Nombre === "Paraná") {
                UtilidadesNetaParana.push(utl.Utilidad);
                // console.log(utl.Nombre);
              }
              if (utl.Nombre === "Victoria") {
                UtilidadesNetaVictoria.push(utl.Utilidad);
                // console.log(utl.Nombre);
              }
            }
            
            // console.log(utl)
        
          });

          // console.table(UtilidadesNetaComercial);
          

          const dataArr = {
            labels: labels,
            datasets: [
              {
                label: "Chajarí",
                borderColor: "rgb(166, 22, 0)",
                backgroundColor: "rgba(166, 22, 0, 0.5)",
                data: UtilidadesNetaChajari,
              },
              {
                label: "Colón",
                borderColor: "rgb(30, 148, 36)",
                backgroundColor: "rgba(30, 148, 36, 0.5)",
                data: UtilidadesNetaColon,
              },
              {
                label: "Concordia",
                borderColor: "rgb(207, 0, 149)",
                backgroundColor: "rgba(207, 0, 149,0.5)",
                data: UtilidadesNetaConcordia,
              },
              {
                label: "Federación",
                borderColor: "rgb(21, 143, 245)",
                backgroundColor: "rgba(21, 143, 245, 0.5)",
                data: UtilidadesNetaFederacion,
              },
              {
                label: "Gualeguaychú",
                borderColor: "rgb(54, 162, 235)",
                backgroundColor: "rgba(54, 162, 235, 0.5)",
                data: UtilidadesNetaGualeguaychu,
              },
              {
                label: "La Paz",
                borderColor: "rgb(75, 192, 192)",
                backgroundColor: "rgba(75, 192, 192, 0.5)",
                data: UtilidadesNetaLaPaz,
              },
              {
                label: "Paraná",
                borderColor: "rgb(153, 102, 255)",
                backgroundColor: "rgba(153, 102, 255, 0.5)",
                data: UtilidadesNetaParana,
              },
              {
                label: "Victoria",
                borderColor: "rgb(255, 205, 86)",
                backgroundColor: "rgba(255, 205, 86, 0.5)",
                data: UtilidadesNetaVictoria,
              }
            ]
          };
          setdata(dataArr);
        })
        .catch(() => {
          console.error("No esta corriendo la API");
          // window.location.replace("/login");
        });
    }
  }, [props.date, source]);

  return (
        <div id="graphContainerCas2">
          <div
              className={
                source ? "bt btText switchActive" : "bt btText switchInactive"
              }
              onClick={() => setsource(!source)}
            >
              {" "}
              recaudación
            </div>
            <div
              className={
                source ? "bt btText switchInactive" : "bt btText switchActive"
              }
              onClick={() => setsource(!source)}
            >
              utilidades
            </div>
          {data !== undefined && (
            <Line id="graphContainer" options={options} data={data} />
          )}
        </div>
      
 
  );
}
