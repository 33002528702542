import { useEffect, useState, useContext, Fragment } from "react";
import { Comercial } from "Functions/tableroApi";
import { dateCompProps, dataUtilidades } from "Models/models";
import { ParseYearMonthDay } from "Functions/functions";
import Numbers from "components/Numbers/Numbers";
import TrafficLight from "components/TrafficLight/TrafficLight";
import { GlobalStatesContext } from "Providers/context";
import SeeExtract from "components/Dashboard/Comercial/SeeExtract";
import PremiosMayores from "components/Dashboard/Comercial/PremiosMayores/PremiosMayores";

export default function Daily(props: dateCompProps) {
  const { state, setContext } = useContext(GlobalStatesContext);
  const [data, setdata] = useState<dataUtilidades[]>();
  const [totales, settotales] = useState<dataUtilidades>({
    Nombre: "",
    Porcentaje1: 0,
    Porcentaje2: 0,
    PremioMayor: "",
    Premios: 0,
    Recaudacion: 0,
    Utilidad: 0,
    UtilidadNeta: 0,
    Acumulado: 0
  });
  const [totalesTomb, settotalesTomb] = useState<dataUtilidades>({
    Nombre: "",
    Porcentaje1: 0,
    Porcentaje2: 0,
    PremioMayor: "",
    Premios: 0,
    Recaudacion: 0,
    Utilidad: 0,
    UtilidadNeta: 0,
    Captacion: 0,
    Comision: 0,
    Acumulado: 0
  });
  const [totalesJuegos, settotalesJuegos] = useState<dataUtilidades>({
    Nombre: "",
    Porcentaje1: 0,
    Porcentaje2: 0,
    PremioMayor: "",
    Premios: 0,
    Recaudacion: 0,
    Utilidad: 0,
    UtilidadNeta: 0,
    Captacion: 0,
    Comision: 0,
    Acumulado: 0
  });

  let TotalesTombola: dataUtilidades = {
    Nombre: "",
    Porcentaje1: 0,
    Porcentaje2: 0,
    PremioMayor: "",
    Premios: 0,
    Recaudacion: 0,
    Utilidad: 0,
    UtilidadNeta: 0,
    Captacion: 0,
    Comision: 0,
    Acumulado: 0
  };

  let TotalesJuegos: dataUtilidades = {
    Nombre: "",
    Porcentaje1: 0,
    Porcentaje2: 0,
    PremioMayor: "",
    Premios: 0,
    Recaudacion: 0,
    Utilidad: 0,
    UtilidadNeta: 0,
    Captacion: 0,
    Comision: 0,
    Acumulado: 0
  };

  const verExtracto = (Tipo: string | undefined) => {
    if (Tipo !== undefined) {
      setContext({
        ...state,
        modal: {
          ...state.modal,
          body: <div className="comercialModalDivsH"><SeeExtract Tipo={Tipo}></SeeExtract></div>,
          show: true,
          button: false,
          title: `Tómbola`,
          subtitle: `Extracto de ${Tipo}`,
        },
      });
    }
  };

  /**
   *
   * @param hay string
   * @description Diego if it´s a Premio Mayor it should return a icon
   * @returns
   */
  const premioMayor = (hay: string): string => {
    if (hay === "No") {
      return "  ";
    } else {
      return " iconPremiosMayores ";
    }
  };

  const onClickPremioMayor = () => {
    setContext({
      ...state,
      modal: {
        ...state.modal,
        show: true,
        body: <PremiosMayores Fecha={state.date} />,
        closeModal: ModalClose,
        subtitle: 'Premios Mayores',
        title: 'Utilidad Comercial'
      },
    });
  };

  const ModalClose = () => {
    setContext({
      ...state,
      modal: {
        ...state.modal,
        show: false,
        subtitle: '',
        title: '',
        body: <></>
      },
    });
  }

  useEffect(() => {
    setContext({ ...state, spinner: { loading: true } });
    const DateSplit = ParseYearMonthDay(props.date);
    Comercial.getComercialDiaria(
      Number(DateSplit?.day),
      Number(DateSplit?.month),
      Number(DateSplit?.year)
    )
      .then((data) => {
        setdata(data);
        let DataRec = 0;
        let DataPre = 0;
        let DataCom = 0;
        let DataUti = 0;
        let DataNet = 0;
        let DataPor1 = 0;
        let DataPor2 = 0;
        let Acum = 0;
        data.forEach((sub, i) => {
          if (sub.Recaudacion !== null) DataRec = DataRec + sub.Recaudacion;
          if (sub.Premios !== null) DataPre = DataPre + sub.Premios;
          if (sub.Comision !== undefined) DataCom = DataCom + sub.Comision;
          if (sub.Utilidad !== null) DataUti = DataUti + sub.Utilidad;
          if (sub.UtilidadNeta !== null) DataNet = DataNet + sub.UtilidadNeta;
          if (sub.Porcentaje1 !== null) DataPor1 = DataPor1 + sub.Porcentaje1;
          if (sub.Porcentaje2 !== null) DataPor2 = DataPor2 + sub.Porcentaje2;
          if (sub.Acumulado !== undefined) Acum = Acum + sub.Acumulado;
          if (sub.CantTombola !== undefined) {
            if (i < sub.CantTombola) {

              TotalesTombola = {
                Recaudacion: TotalesTombola.Recaudacion + sub.Recaudacion,
                Premios: TotalesTombola.Premios + sub.Premios,
                Comision:
                  Number(TotalesTombola.Comision) + Number(sub.Comision),
                Captacion:
                  Number(TotalesTombola.Captacion) + Number(sub.Captacion),
                Utilidad: TotalesTombola.Utilidad + sub.Utilidad,
                UtilidadNeta: TotalesTombola.UtilidadNeta + sub.UtilidadNeta,
                Porcentaje1: 0,
                Porcentaje2: 0,
                PremioMayor: "",
                Acumulado: Acum
              };
            } else {

              const Acum1 = sub?.Acumulado ? sub?.Acumulado : 0;
              const Acum2 = TotalesJuegos.Acumulado ? TotalesJuegos.Acumulado : 0;

              TotalesJuegos = {
                Recaudacion: TotalesJuegos.Recaudacion + sub.Recaudacion,
                Premios: TotalesJuegos.Premios + sub.Premios,
                Comision: Number(TotalesJuegos.Comision) + Number(sub.Comision),
                Captacion:
                  Number(TotalesJuegos.Captacion) + Number(sub.Captacion),
                Utilidad: TotalesJuegos.Utilidad + sub.Utilidad,
                UtilidadNeta: TotalesJuegos.UtilidadNeta + sub.UtilidadNeta,
                Porcentaje1: 0,
                Porcentaje2: 0,
                PremioMayor: "",
                Acumulado: Acum2 + Acum1
              };
            }
          }
        });
        settotalesTomb(TotalesTombola);
        settotalesJuegos(TotalesJuegos);
        settotales({
          ...totales,
          Nombre: "s",
          Recaudacion: DataRec,
          Premios: DataPre,
          Comision: DataCom,
          Utilidad: DataUti,
          UtilidadNeta: DataNet,
          Porcentaje1: (DataUti / DataRec) * 100,
          Porcentaje2: (DataNet / DataRec) * 100,
          PremioMayor: "no",
          Acumulado: Acum
        });


        TotalesTombola = {
          Nombre: "",
          Porcentaje1: 0,
          Porcentaje2: 0,
          PremioMayor: "",
          Premios: 0,
          Recaudacion: 0,
          Utilidad: 0,
          UtilidadNeta: 0,
          Captacion: 0,
          Comision: 0,
          Acumulado: 0
        };

        TotalesJuegos = {
          Nombre: "",
          Porcentaje1: 0,
          Porcentaje2: 0,
          PremioMayor: "",
          Premios: 0,
          Recaudacion: 0,
          Utilidad: 0,
          UtilidadNeta: 0,
          Captacion: 0,
          Comision: 0,
          Acumulado: 0
        };
      })
      .catch(() => {
        setContext({
          ...state,
          toast: {
            text: "Hubo un problema al conectar con el WS.",
            show: true,
          },
        });
        console.log("No esta corriendo la API");
      }).finally(() => {
        setContext({ ...state, spinner: { loading: false } });
      })


  }, [props.date]);

  let Comision: number = 0;
  if (totalesTomb.Comision !== undefined) {
    Comision = totalesTomb.Comision;
  }
  return (
    <>
      <div className="cardTitulo">
        <div className="icon "></div>TOTALES DIARIOS TÓMBOLA [
        {ParseYearMonthDay(props.date)?.day}/
        {ParseYearMonthDay(props.date)?.month}/
        {ParseYearMonthDay(props.date)?.year}]
      </div>
      <div className="tablaContainer">
        <table className="tabla">
          <thead>
            <tr className="cardTotales">
              <th>
                <div className="celda textFont">TOTAL</div>
              </th>
              <th>
                <div className="celda dataFont smallTotal">
                  <span className="dataSymbol">$</span>
                  <Numbers value={totales.Recaudacion} />
                </div>
              </th>
              <th >
                <div className="celda dataFont smallTotal">
                  <span className="dataSymbol">$</span>
                  <Numbers value={totales.Premios} />
                </div>
              </th>
              <th>
                <div className="celda dataFont smallTotal">
                  <span className="dataSymbol">$</span>
                  {totales.Comision && <Numbers value={totales.Comision} />}
                </div>
              </th>
              <th>
                <div className="celda dataFont smallTotal">
                  <span className="dataSymbol">$</span>
                  <Numbers value={totales.Utilidad} />
                </div>
              </th>
              <th >
                <div className="celda dataFont smallTotal">
                  <span className="dataSymbol">$</span>
                  {totales.Acumulado && <Numbers value={totales.Acumulado} />}
                </div>
              </th>
              <th>
                <div className="celda dataFont smallTotal porcentaje specialCase">
                  <Numbers
                    value={(totales.Utilidad / totales.Recaudacion) * 100}
                  />
                  <span>%</span>
                </div>
              </th>
              <th colSpan={2}>
                <div className="celda dataFont bigTotal">
                  <span className="dataSymbol">$</span>
                  <Numbers total={true} value={totales.UtilidadNeta} />
                </div>
              </th>
              {/**<th>
                <div className="celda dataFont smallTotal porcentaje specialCase">
                  <Numbers
                    value={(totales.UtilidadNeta / totales.Recaudacion) * 100}
                  />
                  <span>%</span>
                </div>
        </th>**/}
            </tr>
          </thead>
          <thead>
            <tr className="cardTotales">
              <th>
                <div className="celda textFont">
                  tómbola
                </div>
              </th>
              <th>
                <div className="celda dataFont smallTotal">
                  <span className="dataSymbol">$</span>
                  <Numbers value={totalesTomb.Recaudacion} />
                </div>
              </th>
              <th>
                <div className="celda dataFont smallTotal">
                  <span className="dataSymbol">$</span>
                  <Numbers value={totalesTomb.Premios} />
                </div>
              </th>
              <th>
                <div className="celda dataFont smallTotal">
                  <span className="dataSymbol">$</span>
                  <Numbers value={Comision} />
                </div>
              </th>
              <th>
                <div className="celda dataFont smallTotal">
                  <span className="dataSymbol">$</span>
                  <Numbers value={totalesTomb.Utilidad} />
                </div>
              </th>
              <th>
                <div className="celda dataFont smallTotal">
                  <span className="dataSymbol">$</span>
                  {totalesTomb.Acumulado && <Numbers value={totalesTomb.Acumulado} />}
                </div>
              </th>
              <th>
                <div className="celda dataFont smallTotal porcentaje">
                  <Numbers
                    value={
                      (totalesTomb.Utilidad / totalesTomb.Recaudacion) * 100
                    }
                  />
                  <span>%</span>
                </div>
              </th>
              <th colSpan={2}>
                <div className="celda dataFont bigTotal">
                  <span className="dataSymbol">$</span>
                  <Numbers total={true} value={totalesTomb.UtilidadNeta} />
                </div>
              </th>
              {/**<th>
                <div className="celda dataFont smallTotal porcentaje">
                  <Numbers
                    value={
                      (totalesTomb.UtilidadNeta / totalesTomb.Recaudacion) * 100
                    }
                  />
                  <span>%</span>
                </div>
                  </th>**/}
            </tr>
          </thead>
          <thead className="textFont">
            <tr>
              <th>unidad</th>
              <th>recaudación</th>
              <th>premios</th>
              <th>comsión</th>
              <th>utilidad bruta</th>
              <th>ut. br. acum.</th>
              <th>%</th>
              <th>captación</th>
              <th>utilidad neta</th>
              {/**<th>%</th>**/}
            </tr>
          </thead>
          <tbody>
            {data?.map((com, i) => {
              let Captacion: number = 0;
              let Comision: number = 0;
              if (com.Captacion !== undefined) {
                Captacion = com.Captacion;
              }
              if (com.Comision !== undefined) {
                Comision = com.Comision;
              }
              if (com.CantTombola !== undefined && com.CantTombola > i) {
                return (
                  <tr key={`daily${i}`}>
                    <td>
                      <div className="celda">
                        <div className="textFont cursorManito"
                          onClick={() =>
                            verExtracto(com.Nombre?.replace("Tómbola ", ""))
                          }
                        >
                          {com.Nombre?.replace("Tómbola", "")}
                        </div>
                        <div
                          onClick={onClickPremioMayor}
                          className={
                            premioMayor(com.PremioMayor) +
                            "  "
                          }
                        ></div>
                      </div>
                    </td>
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        <Numbers value={com.Recaudacion} />
                      </div>
                    </td>
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        <Numbers value={com.Premios} />
                      </div>
                    </td>
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        <Numbers value={Comision} />
                      </div>
                    </td>
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        <Numbers value={com.Utilidad} />
                      </div>
                    </td>
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        {com.Acumulado && <Numbers value={com.Acumulado} />}
                      </div>
                    </td>
                    <td className="celda dataFont porcentaje" title="% Recaudación sobre Utilidad Bruta">
                      <TrafficLight
                        value={(com.Utilidad / com.Recaudacion) * 100}
                      />
                    </td>

                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        <Numbers value={Captacion} />
                      </div>
                    </td>
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        <Numbers value={com.UtilidadNeta} />
                      </div>
                    </td>
                    {/**<td className="celda dataFont porcentaje">
                      <TrafficLight
                        value={(com.UtilidadNeta / com.Recaudacion) * 100}
                      />
                        </td>**/}
                  </tr>
                );
              } else {
                return <Fragment key={`vacio${i}`}></Fragment>;
              }
            })}
          </tbody>
        </table>
      </div>
      <div className="comercialEspacioTabla"></div>
      <div className="tablaContainer">
        <table className="tabla tablaComercialXM">
          <thead>
            <tr className="cardTotales">
              <th>
                <div className="celda textFont">subtotal otros</div>
              </th>
              <th>
                <div className="celda dataFont smallTotal">
                  <span className="dataSymbol">$</span>
                  <Numbers value={totalesJuegos.Recaudacion} />
                </div>
              </th>
              <th>
                <div className="celda dataFont smallTotal">
                  <span className="dataSymbol">$</span>
                  <Numbers value={totalesJuegos.Premios} />
                </div>
              </th>
              <th>
                <div className="celda dataFont smallTotal">
                  <span className="dataSymbol">$</span>
                  {totalesJuegos.Comision && <Numbers value={totalesJuegos.Comision} />}
                </div>
              </th>
              <th>
                <div className="celda dataFont smallTotal">
                  <span className="dataSymbol">$</span>
                  <Numbers value={totalesJuegos.Utilidad} />
                </div>
              </th>
              <th>
                <div className="celda dataFont smallTotal">
                  <span className="dataSymbol">$</span>
                  {totalesJuegos.Acumulado && <Numbers value={totalesJuegos.Acumulado} />}
                </div>
              </th>
              <th>
                <div className="celda dataFont smallTotal porcentaje">
                  <Numbers
                    value={
                      (totalesJuegos.Utilidad / totalesJuegos.Recaudacion) * 100
                    }
                  />
                  <span>%</span>
                </div>
              </th>
              <th colSpan={2}>
                <div className="celda dataFont bigTotal">
                  <span className="dataSymbol">$</span>
                  <Numbers total={true} value={totalesJuegos.UtilidadNeta} />
                </div>
              </th>
              {/**<th>
                <div className="celda dataFont smallTotal porcentaje">
                  <Numbers
                    value={
                      (totalesJuegos.UtilidadNeta / totalesJuegos.Recaudacion) *
                      100
                    }
                  />
                  <span>%</span>
                </div>
                  </th>**/}
            </tr>
          </thead>
          <thead className="textFont">
            <tr>
              <th>unidad</th>
              <th>recaudación</th>
              <th>premios</th>
              <th>comisión</th>
              <th>utilidad bruta</th>
              <th>ut. br. acum.</th>
              <th title='% Recaudación sobre Utilidad Bruta'>%</th>
              <th>captación</th>
              <th>utilidad neta</th>
              {/**<th>%</th>**/}
            </tr>
          </thead>
          <tbody>
            {data?.map((com, i) => {
              let Captacion: number = 0;
              if (com.Captacion !== undefined) {
                Captacion = com.Captacion;
              }
              if (com.CantTombola !== undefined && com.CantTombola <= i) {
                return (
                  <tr key={`daily${i}`}>
                    <td>
                      <div className="celda textFont">{com.Nombre}</div>
                    </td>
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        <Numbers value={com.Recaudacion} />
                      </div>
                    </td>
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        <Numbers value={com.Premios} />
                      </div>
                    </td>
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        {com.Comision && <Numbers value={com.Comision} />}
                      </div>
                    </td>
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        <Numbers value={com.Utilidad} />
                      </div>
                    </td>
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        {com.Acumulado && <Numbers value={com.Acumulado} />}
                      </div>
                    </td>
                    <td className="celda dataFont porcentaje" title="% Recaudación sobre Utilidad Bruta">
                      <TrafficLight
                        value={(com.Utilidad / com.Recaudacion) * 100}
                      />
                    </td>
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        <Numbers value={Captacion} />
                      </div>
                    </td>
                    <td>
                      <div className="celda dataFont">
                        <span className="dataSymbol">$</span>
                        <Numbers value={com.UtilidadNeta} />
                      </div>
                    </td>
                    {/**<td className="celda dataFont porcentaje">
                      <TrafficLight
                        value={(com.UtilidadNeta / com.Recaudacion) * 100}
                      />
                </td>**/}
                  </tr>
                );
              } else {
                return <Fragment key={`vacionotombola${i}`}></Fragment>;
              }
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
